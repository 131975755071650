import React from "react";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import debounce from "debounce";

import "./style.scss";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYW1haG9uIiwiYSI6ImNpcmgzejZyeDAxOTFnNm5yZXVocDNvcjYifQ.HbwFh7tdIgCDvyyBFOHQFA";

class Map extends React.Component {
  map;

  constructor(props) {
    super(props);

    this.updateYearFilterDebounced = debounce(this.updateYearFilter, 500);
    this.updateDayFilterDebounced = debounce(this.updateDayFilter, 500);
  }

  pointColors() {
    color_table;

    output = ["match", ["get", "STAT_CAUSE_CODE"]];
    for (color in color_table) {
      console.log(color);
    }
  }

  updateYearFilter(nextProps) {
    console.log("updateYearFilter");
    this.map.setFilter(
      "fire-data",
      [">", "FIRE_YEAR", nextProps.year[0]],
      ["<", "FIRE_YEAR", nextProps.year[1]]
    );
  }

  updateDayFilter(nextProps) {
    console.log("updateDayFilter");
    this.map.setFilter(
      "fire-data",
      [">", "DISCOVERY_DOY", nextProps.day[0]],
      ["<", "DISCOVERY_DOY", nextProps.day[1]]
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.year[0] != this.props.year[0] ||
      nextProps.year[1] != this.props.year[1]
    ) {
      //update year filter
      console.log("update year");
      console.log(nextProps.year);
      this.updateYearFilterDebounced(nextProps);
    }
    if (
      nextProps.day[0] != this.props.day[0] ||
      nextProps.day[1] != this.props.day[1]
    ) {
      //update day filter
      console.log("update day");
      console.log(nextProps.day);
      this.updateDayFilterDebounced(nextProps);
    }
  }

  componentDidMount() {
    console.log("Map.componentDidMount");

    var _map;

    _map = new mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/mapbox/dark-v9",
      center: [-103.850278, 44.6675],
      zoom: 2.5,
    });

    this.map = _map;

    // _map.on('mousemove', function (e) {
    //     var features = _map.queryRenderedFeatures(e.point);
    //     document.getElementById('features').innerHTML = JSON.stringify(features, null, 2);
    // });

    _map.on("zoom", () => {
      console.log(_map.getZoom());
    });

    _map.on("load", () => {
      _map.addControl(new mapboxgl.NavigationControl());
      _map.addControl(new mapboxgl.FullscreenControl());

      _map.addLayer(
        {
          id: "fire-data",
          type: "circle",
          source: {
            type: "vector",
            tiles: [
              "https://s3-us-west-1.amazonaws.com/fire-map-4/{z}/{x}/{y}",
            ],
          },
          "source-layer": "fires",
          paint: {
            "circle-color": [
              "match",
              ["get", "STAT_CAUSE_CODE"],
              5,
              "#000004",
              9,
              "#110A30",
              7,
              "#320A5E",
              1,
              "#57106E",
              13,
              "#781C6D",
              2,
              "#9A2865",
              4,
              "#BC3754",
              8,
              "#D84C3E",
              3,
              "#ED6925",
              6,
              "#F98C0A",
              11,
              "#FCB418",
              10,
              "#F4DF53",
              12,
              "#FCFFA4",
              "#ff0000",
            ],
            //'circle-color': '#ff0000',
            "circle-opacity": 0.85,
            "circle-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              1,
              5,
              1.5,
              10,
              3,
              15,
              25,
              22,
              150,
            ],
            "circle-blur": 0.1,
          },
        },
        "place-city-sm"
      );

      _map.addLayer({
        id: "fire-data-geohash-3-counts",
        type: "fill",
        source: {
          type: "vector",
          tiles: [
            "https://s3-us-west-1.amazonaws.com/fire-map/geohash_5_count_by_year/{z}/{x}/{y}",
          ],
        },
        "source-layer": "fires",
        paint: {},
      });

      // _map.addLayer({
      //   "id": "geohash_3_counts",
      //   "type": "polygon",
      //   "source": {
      //       type: 'vector',
      //       url: 'mapbox://mapbox.mapbox-terrain-v2'
      //   },
      //   "source-layer": "contour",
      //   "layout": {
      //       "line-join": "round",
      //       "line-cap": "round"
      //   },
      //   "paint": {
      //       "line-color": "#ff69b4",
      //       "line-width": 1
      //   }
      // });
    });
  }

  render() {
    return (
      <div>
        <div ref={(el) => (this.mapContainer = el)} className="map" />
      </div>
    );
  }
}

export default Map;
